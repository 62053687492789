<template>
  <protection-leads-widget />
</template>

<script>
import ProtectionLeadsWidget from '@/view/pages/leads/ProtectionLeadsWidget.vue';
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "leads",
  components: {
    ProtectionLeadsWidget
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Protection Leads" }]);
  }
}
</script>

<style>

</style>